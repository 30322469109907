import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Home1 from '../views/Home1.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
// import Preselection from '../views/Preselection.vue'
// import Selection from '../views/Selection.vue'
import Status from '../views/Status.vue'
import EmailVerification from '../views/EmailVerification.vue'
import PasswordForget from '../views/PasswordForget.vue'
// import Navbar from '../components/TheNavbar.vue'
import Navbar1 from '../components/TheNavbar1.vue'
import Footer from '../components/TheFooter.vue'
import NotFound from '../components/The404Page.vue'
// import Evaluation from '../components/Evaluation.vue'
import Footer1 from '../components/Footer1.vue'
// import ProgramAccompagn from '../components/ProgramAccompagn.vue'
// import ProgramFormation from '../components/ProgramFormation.vue'
import Nav from '../components/Navbar.vue'
import Faq from '../views/Faq.vue'
import Event from '../views/Event.vue'
import Blog from '../views/Blog.vue'
import BlogDetail from '../views/BlogDetail.vue'
import EventDetail from '../views/EventDetail.vue'
import About from '../views/About.vue'
import Project from '../views/Project.vue'
import ProjectSubmission from '../views/ProjectSubmission.vue'
import StatusProject from '../views/StatusProject.vue'
import SupportProgram from '../views/SupportProgram.vue'
import TrainingProgram from '../views/TrainingProgram.vue'
import TrainingDetail from '../views/TrainingDetail.vue'
import SupportDetail from '../views/SupportDetail.vue'


const routes = [

  {
    path: '/',
    name: 'Home1',
    components: {
      navbar: Nav,
      main: Home1,
      footer: Footer
    },
    meta: {
      requiresAuth: false,
      frenchName: 'Accueil1'
    }
  },

  {
    path: '/home',
    name: 'Home',
    components: {
      navbar: Navbar1,
      main: Home,
      footer: Footer,
    },
    meta: {
      requiresAuth: true,
      frenchName: 'Accueil'
    }
  },
  {
    path: '/programme-de-formation',
    name: 'TrainingProgram',
    components: {
      navbar: Nav,
      main: TrainingProgram,
      footer: Footer
    },
    meta: {
      requiresAuth: false,
      frenchName: 'Programme de formation'
    }
  },
  {
    path: '/programme-accompagnement',
    name: 'SupportProgram',
    components: {
      navbar: Nav,
      main: SupportProgram,
      footer: Footer
    },
    meta: {
      requiresAuth: false,
      frenchName: 'Programme d\'accompagnement'
    }
  },
  {
    path: '/formation/:id/:slug',
    name: 'Training',
    components: {
      navbar: Nav,
      main: TrainingDetail,
      footer: Footer
    },
    meta: {
      requiresAuth: false,
      frenchName: 'Formation'
    },
    props: true 
  },
  {
    path: '/accompagnement/:id/:slug',
    name: 'Support',
    components: {
      navbar: Nav,
      main: SupportDetail,
      footer: Footer
    },
    meta: {
      requiresAuth: false,
      frenchName: 'Accompagnement'
    },
    props: true 
  },
  // {
  //   path: '/selection',
  //   name: 'Selection',
  //   components: {
  //     navbar: Navbar,
  //     main: Selection,
  //     footer: Footer
  //   },
  //   meta: {
  //     requiresAuth: true,
  //     frenchName: 'Suis-je sélectionné ?'
  //   }
  // },
  {
    path: '/status',
    name: 'Status',
    components: {
      navbar: Navbar1,
      main: Status,
      footer: Footer,
    },
    meta: {
      requiresAuth: true,
      frenchName: 'Mon activité'
    }
  },
  {
    path: '/status/:id/:title',
    name: 'StatusProject',
    components: {
      navbar: Navbar1,
      main: StatusProject,
      footer: Footer,
    },
    meta: {
      requiresAuth: true,
      frenchName: 'Status Projet'
    }
  },
  {
    path: '/register',
    name: 'Register',
    components: {
      main: Register,
    },
    meta: {
      transition: 'fade'
    }
  },
  // {
  //   path: '/evaluation',
  //   name: 'evaluation',
  //   components: {
  //     navbar: Navbar,
  //     main: Evaluation,
  //     footer: Footer,
  //   },
  // },
  {
    path: '/login',
    name: 'Login',
    components: {
      main: Login,
    },
    meta: {
      transition: 'fade'
    }
  },
  {
    path: '/verify-email',
    name: 'EmailVerification',
    components: {
      main: EmailVerification,
    },
    meta: {
      transition: 'fade'
    }
  },
  {
    path: '/forget-password',
    name: 'PasswordForget',
    components: {
      main: PasswordForget,
    },
    meta: {
      transition: 'fade'
    }
  },
  {
    path: '/reset-password',
    name: 'PasswordReset',
    components: {
      main: () => import(/* webpackChunkName: "about" */ '../views/PasswordReset.vue'),
    },
    meta: {
      transition: 'fade'
    }
  },
  {
    path: '/cgu',
    name: 'CGU',
    components: {
      main: () => import(/* webpackChunkName: "about" */ '../views/CGU.vue'),
    },
    meta: {
      transition: 'fade'
    }
  },
  {
    path: '/new-candidacies',
    name: 'NewCandidacy',
    components: {
      navbar: () => import(/* webpackChunkName: "about" */ '../components/TheNavbar.vue'),
      main: () => import(/* webpackChunkName: "about" */ '../views/NewCandidacy.vue'),
      footer: Footer1,
    },
    meta: {
      requiresAuth: true,
      frenchName: 'Nouvelle candidature'
    }
  },
  {
    path: '/edit-candidacy',
    name: 'EditCandidacy',
    components: {
      navbar: () => import(/* webpackChunkName: "about" */ '../components/TheNavbar.vue'),
      main: () => import(/* webpackChunkName: "about" */ '../views/NewCandidacy.vue'),
      footer: () => import(/* webpackChunkName: "about" */ '../components/TheFooter.vue')
    },
    meta: {
      requiresAuth: true,
      frenchName: 'Modifier ma candidature'
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    components: {
      navbar: Navbar1,
      main: () => import(/* webpackChunkName: "about" */ '../views/Profile.vue'),
      footer: Footer,
    },
    meta: {
      requiresAuth: true,
      frenchName: 'Mon profil'
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    components: {
      navbar: Nav,
      main: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue'),
      footer: Footer
    },
    meta: {
      requiresAuth: false,
      frenchName: 'Nous contacter'
    }
  },
  {
    path: '/faq',
    name: 'Faq',
    components: {
      navbar: Nav,
      main: Faq,
      footer: Footer
    },
    meta: {
      requiresAuth: false,
      frenchName: 'FAQ'
    }
  },
  {
    path: '/evenements',
    name: 'Event',
    components: {
      navbar: Nav,
      main: Event,
      footer: Footer
    },
    meta: {
      requiresAuth: false,
      frenchName: 'Évènement'
    }
  },
  {
    path: '/blog',
    name: 'Blog',
    components: {
      navbar: Nav,
      main: Blog,
      footer: Footer
    },
    meta: {
      requiresAuth: false,
      frenchName: 'Évènement'
    }
  },
  {
    path: '/blog/:id/:slug',
    name: 'Article-detail',
    components: {
      navbar: Nav,
      main: BlogDetail,
      footer: Footer
    },
    meta: {
      requiresAuth: false,
      frenchName: 'Blog Détail'
    },
    props: true 
  },
  {
    path: '/evenement/:id/:slug',
    name: 'Event-detail',
    components: {
      navbar: Nav,
      main: EventDetail,
      footer: Footer
    },
    meta: {
      requiresAuth: false,
      frenchName: 'Evènement Détail'
    },
    props: true 
  },
  {
    path: '/soumission-projet',
    name: 'Project',
    components: {
      navbar: Nav,
      main: Project,
      footer: Footer
    },
    meta: {
      requiresAuth: false,
      frenchName: 'Projet'
    }
  },
  {
    path: '/soumission-projet/formulaire',
    name: 'ProjectSubmission',
    components: {
      navbar: Nav,
      main: ProjectSubmission,
      footer: Footer
    },
    meta: {
      requiresAuth: true,
      frenchName: 'Soumission Projet'
    }
  },
  {
    path: '/about',
    name: 'About',
    components: {
      navbar: Nav,
      main: About,
      footer: Footer
    },
    meta: {
      requiresAuth: false,
      frenchName: 'À propos'
    }
  },
  {
    path: "/:catchAll(.*)",
    name: 'Not-Found',
    components: {
      main: NotFound,
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    return { left: 0, top: 0 }
  },
})

export default router