<template>
  <section id="home">
    <nav class="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0 bg-light fixed-top">
      <router-link to="/" class="navbar-brand p-0">
        <img class="img-fluid m-0" src="../static/Unipod-logo.png" alt="Unipod" width="151" height="55" />
      </router-link>
      <button class="navbar-toggler" type="button" @click="toggleNav">
        <span class="fa fa-bars"></span>
      </button>
      <div :class="['collapse', 'navbar-collapse', {'show': isNavOpen}]" id="navbarCollapse">
        <div class="navbar-nav mx-auto py-0">
          <router-link to="/" class="nav-link nav-item" :class="{ active: isActiveRoute('/') }">Accueil</router-link>

          <router-link to="/about" class="nav-link nav-item" :class="{ active: isActiveRoute('/about') }">A propos</router-link>

          <router-link to="/programme-accompagnement" class="nav-link nav-item" :class="{ active: isActiveRoute('/programme-accompagnement') }">Accompagnement</router-link>

          <router-link to="/programme-de-formation" class="nav-link nav-item" :class="{ active: isActiveRoute('/programme-de-formation') }">Formations</router-link>
          
          <div class="nav-item dropdown" @mouseenter="toggleDropdown('actualites', true)" @mouseleave="toggleDropdown('actualites', false)">
            <a href="#" class="nav-link dropdown-toggle" :class="{ show: isDropdownOpen.actualites, active: isActiveDropdown('actualites') }" @click.prevent="toggleDropdown('actualites', !isDropdownOpen.actualites)">
              Actualités
            </a>
            <div class="dropdown-menu" :class="{ show: isDropdownOpen.actualites }">
              <router-link class="dropdown-item" to="/blog">Blog</router-link>
              <router-link class="dropdown-item" to="/evenements">Evènements</router-link>
            </div>
          </div>
          
          <router-link to="/soumission-projet" class="nav-link nav-item" :class="{ active: isActiveRoute('/soumission-projet') }">Soumettre un projet</router-link>
          <router-link to="/contact" class="nav-link nav-item" :class="{ active: isActiveRoute('/contact') }">Nous contacter</router-link>
        </div>
        
        <div v-if="firstName && lastName" class="btn user-profile py-1 px-2 ms-2">
          <router-link to="/home" class="user-link">
            <div class="user-icon">
              <i class="fa fa-user-circle"></i>
            </div>
            <div class="user-name">
              {{ truncatedName }}
            </div>
          </router-link>
        </div>
        <div v-else>
          <router-link to="/register" class="btn btn-register py-1 px-2 ms-2">
            <span class="fas fa-user"></span> S'inscrire
          </router-link>
          <router-link to="/login" class="btn btn-login py-1 px-2 ms-2">
            <span class="fas fa-sign-in-alt"></span> Se connecter
          </router-link>
        </div>
      </div>
    </nav>
  </section>
</template>

<script>
export default {
  name: "Navigation",
  data() {
    return {
      isNavOpen: false,
      isDropdownOpen: {
        services: false,
        actualites: false
      }
    };
  },
  computed: {
    firstName() {
      return localStorage.getItem("firstName") || "";
    },
    lastName() {
      return localStorage.getItem("lastName") || "";
    },
    truncatedName() {
      const fullName = `${this.lastName} ${this.firstName}`;
      return fullName.length > 24 ? fullName.slice(0, 24).trim() + '...' : fullName;
    },
  },
  methods: {
    toggleNav() {
      this.isNavOpen = !this.isNavOpen;
    },
    toggleDropdown(dropdown, status) {
      this.isDropdownOpen[dropdown] = status;
    },
    isActiveRoute(route) {
      return this.$route.path === route;
    },
    isActiveDropdown(dropdown) {
      const routes = {
        services: ['/formation', '/accompagnement'],
        actualites: ['/blog', '/evenements']
      };
      return routes[dropdown].some(route => this.$route.path.startsWith(route));
    },
  },
};
</script>

<style scoped>
:root {
  --primary: #1D9ED9;
}
body {
  background-color: #1d9ed9;
}

/* btn */
.btn {
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  font-weight: 400;
  transition: all 0.5s ease;
  border-radius: 20px;
  text-decoration: none;
}

.btn-register {
  background-color: #F4D218;
  color: #1D9ED9;
  border: 2px solid #F4D218;
}

.btn-register:hover {
  background-color: #1D9ED9;
  color: #F4D218;
  border-color: #1D9ED9;
}

.btn-login {
  background-color: transparent;
  color: #1D9ED9;
  border: 2px solid #1D9ED9;
}

.btn-login:hover {
  background-color: #1D9ED9;
  color: white;
}

.btn span {
  margin-right: 5px;
}


.user-profile {
  background-color: white;
  border-radius: 10px;
  padding: 5px;
  border-color: #1D9ED9;
}
.user-profile:hover {
  transform: scale(0.95);
}

.user-link {
  display: flex;
  font-family: 'Poppins', regular;
  font-weight: 15px;
  transition: .5s;
  align-items: center;
  text-decoration: none;
  color: #1D9ED9;
}

.user-icon {
  margin-right: 6px;
  color: #1d9dd92f;
}

.user-icon i {
  font-size: 25px;
}

.user-name {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


/*** Navbar ***/
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}
.navbar-dark .navbar-nav .nav-link {
    font-family: 'Poppins', regular;
    position: relative;
    margin-left: 25px;
    padding: 10px 10px;
    color: #1D9ED9;
    font-size: 15px;
    outline: none;
    transition: .5s;
}

.sticky-top.navbar-dark .navbar-nav .nav-link {
    padding: 20px 0;
    color: var(--dark);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
    color: var(--primary);
}

.navbar-dark .navbar-nav .nav-link::before {
    position: absolute;
    content: "";
    width: 0;
    height: 2px;
    bottom: -1px;
    left: 50%;
    background: var(--primary);
    transition: width 0.5s ease, left 0.5s ease;
  }
  
  .navbar-dark .navbar-nav .nav-link:hover::before {
    width: 80%;
  left: 10%;
  }
  
  .navbar-dark .navbar-nav .nav-link.active::before {
    width: 80%;
  left: 10%;
  }

.navbar-dark .navbar-brand h1 {
    color: #FFFFFF;
}

.navbar-dark .navbar-toggler {
    color: var(--primary) !important;
    border-color: var(--primary) !important;
}
.dropdown-item{
  font-family: 'Poppins', regular;
    position: relative;
    /* margin-left: 25px; */
    padding: 10px 10px;
    color: #1D9ED9;
    font-size: 15px;
    outline: none;
    transition: .5s;
}

@media (min-width: 576px) and (max-width: 991px) {
    .sticky-top.navbar-dark {
        position: relative;
        background: #FFFFFF;
    }

    .navbar-dark .navbar-nav .nav-link,
    .navbar-dark .navbar-nav .nav-link.show,
    .sticky-top.navbar-dark .navbar-nav .nav-link {
        padding: 10px 0;
        color: var(--dark);
    }

    .navbar-dark .navbar-brand h1 {
        color: var(--primary);
    }
}

@media (max-width: 576px) {
    .navbar-dark {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        border-bottom: 1px solid rgba(256, 256, 256, .1);
        z-index: 999;
    }
    
    .sticky-top.navbar-dark {
        position: fixed;
        background: #FFFFFF;
    }

    .navbar-dark .navbar-nav .nav-link::before {
        position: absolute;
        content: "";
        width: 0;
        height: 2px;
        bottom: -1px;
        left: 50%;
        background: var(--primary);
        transition: .5s;
    }

    .navbar-dark .navbar-nav .nav-link:hover::before,
    .navbar-dark .navbar-nav .nav-link.active::before {
        width: 100%;
        left: 0;
    }

    .navbar-dark .navbar-nav .nav-link.nav-contact::before {
        display: none;
    }

    .sticky-top.navbar-dark .navbar-brand h1 {
        color: var(--primary);
    }
}
</style>