<template>
    <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <!-- Header -->
    <div class="row py-5 bg-header" style="margin-bottom: 20px; position: relative;">
      <div class="col-12 pt-lg-5 mt-5 text-center" style="position: relative; z-index: 2;">
        <h1 class="display-4 text-white animated zoomIn mb-3"><strong>Programmes de Formations</strong></h1>
        <a href="/" class="h5 text-white" style="text-decoration: none;">Accueil</a>
        <span class="h5 text-white"> / </span>
        <span class="h5 banner-cta"><b>Formations</b></span> <br /><br />
      </div>
    </div>

    <div class="container" style="margin-top: 90px; margin-bottom: 50px;">
      <h2 class="title">{{ post.title }}</h2>
      <div class="image-container">
        <img :src="imageURL" :alt="post.title" class="card-image-slide">
      </div>
  
      <div class="date-info mb-4">
        <template v-if="post.start_date !== post.end_date">
          <span class="date-item"><i class="far fa-calendar-alt me-2"></i>Début : {{ formatDate(post.start_date) }}</span>
          <span class="date-item"><i class="far fa-calendar-alt me-2"></i>Fin : {{ formatDate(post.end_date) }}</span>
        </template>
        <template v-else>
          <span class="date-item"><i class="far fa-calendar-alt me-2"></i>Date de l'évènement : {{ formatDate(post.start_date) }}</span>
        </template>
      </div>
  
      <div class="content" v-html="post.content"></div>
  
      <div class="action-section mt-5">
        <a v-if="post.status === 'en_cours'" :href="post.form_link" class="btn btn-primary btn-postuler">Postuler Maintenant</a>
        <div class="share-buttons">
          <span class="share-label">Partager</span>
          <div class="share-icons">
            <a :href="shareOnFacebook()" target="_blank" class="share-button">
              <i class="fab fa-facebook"></i>
            </a>
            <a :href="shareOnTwitter()" target="_blank" class="share-button">
              <i class="fab fa-twitter"></i>
            </a>
            <a :href="shareOnLinkedIn()" target="_blank" class="share-button">
              <i class="fab fa-linkedin"></i>
            </a>
            <a href="#" class="share-button" @click="shareByEmail">
              <i class="fa fa-envelope"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import AOS from "aos";
export default {
    data() {
        return {
            post: {},
            imageURL: '',
        };
    },
    mounted() {
        // Ici, vous devez récupérer l'post en fonction de l'id passé dans l'URL
        const id = this.$route.params.id;
        const slug = this.$route.params.slug;
        // console.log('ID:', id);
        // console.log('Slug:', slug);

        this.getArticleById(id, slug);
        // this.fetchImage();

        document.querySelector("body").style.backgroundColor = "#ffffff";
        AOS.init();
    },
    methods: {
        getArticleById(id, slug) {
            this.$http.get(`blog/${id}/${slug}`)
                .then(response => {
                    this.post = response.data;
                    this.fetchImage(); // Appeler fetchImage() ici une fois que les données sont récupérées
                    // console.log(this.post.content);
                })
                .catch(error => {
                    console.error(error);
                });
        },
        fetchImage() {
            if (this.post.image) {
                const baseURL = "https://admin.benin-unipod.bj/api/candidate";
                // const baseURL = "http://127.0.0.1:8000/api/candidate";
                const url = `storage/${this.post.image}`;
                const imageUrl = `${baseURL}/${url}`;
                // console.log('URL de l\'image:', imageUrl);
                this.$http.get(imageUrl);
                // Faites une requête à votre API pour récupérer l'URL de l'image
                this.imageURL = imageUrl;
                // console.log(this.imageURL);
            } else {
                console.error('Aucune image n\'est définie pour cet article.');
            }
        },

        formatDate(date) {
            return new Date(date).toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' });
        },

        shareOnFacebook() {
            return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`;
        },

        shareOnTwitter() {
            const shareText = encodeURIComponent(this.post.title);
            return `https://twitter.com/intent/tweet?text=${shareText}%0A&url=${encodeURIComponent(window.location.href)}`;
        },

        shareOnLinkedIn() {
            return `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(this.post.title)}&summary=${encodeURIComponent(this.post.extract)}&source=`;
        },

        shareByEmail() {
            const subject = encodeURIComponent(this.post.title);
            const body = encodeURIComponent(this.post.extract`\n` `lire plus: ${window.location.href}`);
            const shareURL = `mailto:?subject=${subject}&body=${body}`;
            window.open(shareURL, '_blank');
        }

    }
};
</script>

<style scoped>
.bg-header {
  position: relative;
  background: url('../static/formation.jpg') center/cover no-repeat;
  padding: 60px 0;
}

.bg-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1d9dd96f;
  pointer-events: none;
}

.banner-cta {
  display: inline-block;
  color: #F4D218;
  padding: 2px 4px;
  border-radius: 30px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;
}

.banner-cta:hover {
  background-color: #f4d31874;
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.title {
  margin-bottom: 30px;
  color: black;
  font-weight: bold;
}

.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.card-image-slide {
  max-width: 100%;
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.date-info {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.date-item {
  display: flex;
  align-items: center;
  color: black;
}

.date-item i {
  color: #1D9ED9;
}

.content {
  font-family: 'proxima-nova', sans-serif;
  font-size: 1.1em;
  line-height: 1.6;
  color: #333;
}

.action-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
}

.btn-postuler {
  font-size: 1.2rem;
  padding: 10px 30px;
  background-color: #1D9ED9;
  border: none;
  transition: background-color 0.3s ease;
}

.btn-postuler:hover {
  background-color: #1789bd;
}

.share-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.share-label {
  color: #808285;
  font-size: 1.1rem;
  margin-bottom: 15px;
  border-bottom: 2px solid #F4D218;
  padding-bottom: 5px;
}

.share-icons {
  display: flex;
  gap: 10px;
}

.share-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #1D9ED9;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.share-button:hover {
  background-color: #1789bd;
}

.share-button i {
  color: #fff;
  font-size: 18px;
}

@media (max-width: 768px) {
  .action-section {
    flex-direction: column;
    align-items: stretch;
  }

  .btn-postuler {
    width: 100%;
    text-align: center;
  }

  .share-buttons {
    align-items: center;
  }
}

@media (min-width: 992px) {
  .bg-header {
    position: relative;
    background: url('../static/formation.jpg') center/cover no-repeat;
    height: 500px;
  }
}
</style>